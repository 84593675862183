import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MyLoading from "./loading";

export default function CQpay() {
  let { gereecode } = useParams();
  const [dun, setDun] = useState(0);
  const [qr_image, setQr_image] = useState("");
  const [urls, setUrls] = useState([]);
  const [iserror, setIserror] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (gereecode.length !== 9) {
      setIserror(true);
    }
    if (isNaN(gereecode)) {
      setIserror(true);
    }
    if (!iserror) {
      setLoading(true);
      axios
        .post("https://sain.cscc.mn/", {
          oper: "qpayhaalt",
          cntrCd: gereecode,
        })
        .then((res) => {
          //console.log(res);
          if (!res.data.qr_image) {
            setIserror(true);
            setLoading(false);
          } else {
            setDun(res.data.dun);
            setQr_image(res.data.qr_image);
            setUrls(res.data.urls);
            setLoading(false);
          }
        })
        .catch((err) => {
          setIserror(true);
          console.log(err);
          setLoading(false);
        });
    }
  }, [gereecode]);

  if (iserror) {
    return <div>Гэрээний дугаар олдсонгүй</div>;
  }

  if (loading) {
    return <MyLoading />;
  }

  return (
    <div>
      <h3>Сайн Ломбард</h3>
      <img
        src={`data:image/png;base64,${qr_image}`}
        width={300}
        height={300}
        alt=""
      />
      <p style={{ marginTop: "0px", marginBottom: "0px" }}>
        Хаах дүн : {dun - 300} төг
      </p>
      <p style={{ marginTop: "0px", marginBottom: "0px" }}>
        QPay хураамж : 300 төг
      </p>
      <p style={{ marginTop: "0px" }}>Гэрээний код : {gereecode}</p>
      {urls?.map((el, i) => {
        return (
          <a key={i} href={el.link} style={{ marginRight: "5px" }}>
            <img src={el.logo} width={50} height={50} alt={el.desctiption} />
          </a>
        );
      })}
    </div>
  );
}
